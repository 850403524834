<template>
  <popup-modal ref="popup">
    <div class="modal-dialog">
      <template v-if="!loading">
        <div class="modal-content" style="max-height: 90vh">
          <div class="modal-body scrollable">
            <div>
              <div class="col d-flex align-items-center justify-content-center">
                <!--                Title-->
                <span class="h5 text-primary m-0"> Select A Manager </span>
              </div>
            </div>

            <div class="col-12 align-items-center my-5">
              <!--                    Description-->
              <div class="form-row">
                <div class="col-9">
                  <DropdownComponent
                    :pre-selected="managerId"
                    placeholder="Select a Manager"
                    size="sm"
                    v-model="managerId"
                    :options="managers"
                    :fields="['id', 'firstName', 'lastName']"
                    filterable
                  ></DropdownComponent>
                </div>
                <div class="col-3">
                  <button
                    :disabled="btnLoading"
                    @click.prevent="save()"
                    class="btn btn-success btn-sm btn-block"
                  >
                    <span
                      v-if="btnLoading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-else> Save </span>
                  </button>
                </div>
              </div>
            </div>

            <!--              Buttons-->
            <div class="row">
              <div class="col text-center">
                <button
                  @click="$refs.popup.close()"
                  :class="`btn btn-primary w-50`"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <loading-component></loading-component>
      </template>
    </div>
  </popup-modal>

  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script>
import { defineComponent } from "vue";
import PopupModal from "./PopupModal.vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import DropdownComponent from "@/components/DropdownComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  name: "DropDownModal",

  components: {
    PopupModal,
    DropdownComponent,
    ConfirmationModal,
  },

  data: () => ({
    // Parameters that change depending on the type of dialogue

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,

    workForceService: new WorkForceService(),
    managers: [],
    managerId: 0,
    loading: false,
    fields: { text: "firstName", value: "id" },
    employeeId: 0,
    btnLoading: false,
  }),

  methods: {
    resetData() {
      this.$refs.dropdownlist.clear();
    },

    async save() {
      this.btnLoading = true;
      await this.workForceService
        .postOrPut(
          `/employee/Manager/${this.employeeId}?managerId=${
            this.managerId ? this.managerId : 0
          }`,
          Number(this.managerId ? this.managerId : 0),
          this.employeeId.toString()
        )
        .then((response) => {
          if (!response.isError) {
            this.btnLoading = false;
            this._confirm();
          } else if (response.isError) {
            this._cancel(response.message);
          } else {
            return;
          }
        });

      this.btnLoading = false;
    },

    async show(opts = {}) {
      this.employeeId = await opts.employeeId;
      this.managerId = await opts.managerId;

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();

      await this.fetchApis();

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async fetchApis() {
      this.loading = true;

      await this.workForceService
        .get(`/employee/managers`, false)
        .then((response) => {
          this.managers = response.content;
        });
      this.loading = false;
    },
    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
      this.managerId = 0;
    },

    _cancel(e) {
      this.$refs.popup.close();
      this.managerId = 0;
      const modal = this.$refs.confirmDialogue.show({
        title: "Can not Assign Manager!",
        message: e || "Something went wrong",
        okButton: "Ok",
        icon: "danger",
        type: "notification",
      });
      if (modal) {
        return false;
      } else {
        return false;
      }
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
});
</script>

<style scoped>
.icon-mod {
  border-radius: 5px;
  font-size: 100%;
}
.scrollable {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
