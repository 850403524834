import { Options, Vue } from "vue-class-component";
import { IEmployeeList, IManager } from "@/types/EmployeeList";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import DropDownModal from "@/components/modals/DropDownModal.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import Filter from "@/components/Filter.vue";
import { IDepartment } from "@/types/Department";
import AuthStore from "@/store/auth-store";
import { notify } from "@/services/helpers";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "Employees",
  components: {
    ProfileImage,
    Breadcrumb,
    ConfirmationModal,
    DropDownModal,
    Filter,
    PaginationComponent,
  },
  created() {
    return;
  },
  data() {
    return {
      authId: AuthStore.getTokenData().EmployeeId,
      authRole: AuthStore.getTokenData().Roles,
    };
  },
})
export default class EmployeesList extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  employees: IEmployeeList[] = [];
  perPage = 15;
  totalCount = 0;
  currentPage = 0;
  totalFilterCount = 0;
  employeeId = 0;
  crumbs: any = ["Dashboard", "Employees"];
  $refs!: {
    confirmDialogue: HTMLFormElement;
    dropDown: HTMLFormElement;
    filter: HTMLFormElement;
  };

  filterData = [
    {
      key: "FirstName",
      keyLabel: "First Name",
      dataType: "string",
    },
    {
      key: "LastName",
      keyLabel: "Last Name",
      dataType: "string",
    },
    {
      key: "Email",
      keyLabel: "Email",
      dataType: "string",
    },
    {
      key: "Role",
      keyLabel: "Role",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: "Employee",
          label: "Employee",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Org Admin",
          label: "Organization Admin",
        },
      ],
    },
    {
      key: "IsActive",
      keyLabel: "Is Active",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: true,
          label: "Active",
        },
        {
          value: false,
          label: "Not Active",
        },
      ],
    },
    {
      key: "IsVerified",
      keyLabel: "Is Verified",
      dataType: "dropDowns",
      dropDownOptions: [
        {
          value: true,
          label: "Verified",
        },
        {
          value: false,
          label: "Not Verified",
        },
      ],
    },
  ];

  // For Pagination
  savedPaginationQuery = "";

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  canInviteOrEdit(loggedInRole: string, inviteeRole: string) {
    if (
      loggedInRole == "Manager" &&
      (inviteeRole == "Org Admin" || inviteeRole == "Admin")
    ) {
      return false;
    }
    return true;
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.savedPaginationQuery = `PageIndex=${page}&PageSize=${this.perPage}`;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?${this.savedSort ? "SortBy=" + this.savedSort + "&" : ""}${
          this.savedFilterQuery ? this.savedFilterQuery : ""
        }PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.employees = response.content.items);
      });
  }

  // For Filter
  filtered = false;
  savedFilterQuery = null;
  savedSort = null;
  async filter(query: any) {
    // if any filter is already applied when paginating
    this.savedFilterQuery = query;
    // Checks if the sort is already applied: if applied then adds it with filter query
    const sortQuery = this.savedSort ? "SortBy=" + this.savedSort : "";
    this.filtered = false;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?${sortQuery}&${query}&${this.savedPaginationQuery}`,
        false
      )
      .then((response: any) => {
        this.employees = response.content.items;
        this.totalFilterCount = response.content.totalCount;
        this.totalCount = response.content.totalCount;
        this.filtered = true;
      });
  }

  async sort(sortBy: any) {
    this.filtered = false;
    this.savedSort = sortBy;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/employee?SortBy=${sortBy}&${this.savedFilterQuery}&${this.savedPaginationQuery}`,
        false
      )
      .then((response: any) => {
        this.employees = response.content.items;
        this.totalFilterCount = response.content.totalCount;
        this.totalCount = response.content.totalCount;
        this.filtered = true;
      });
  }

  async setRole(employeeId: number, role: string) {
    const modal = await this.$refs.confirmDialogue.show({
      title: "Attention!",
      message: "Are you sure you want to change this role?",
      okButton: "Ok, Proceed",
      cancelButton: "Cancel",
      icon: "info",
      type: "confirmation",
    });

    if (modal) {
      this.workForceService
        .postOrPut<void>(
          `/account/${employeeId}?newRole=${role}`,
          undefined,
          employeeId.toString()
        )
        .then((response: any) => {
          if (response.isError) {
            notify(response.errors, "Cannot assign role!", "danger");
          } else {
            notify("Role assigned successfully.", "Success", "success");
          }
          this.initialize();
        });
    } else {
      return false;
    }
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  checkKey(arrObj: any, value: string) {
    return arrObj.some((obj: { key: string }) => obj.key === value);
  }

  async clearFilter() {
    this.savedFilterQuery = null;
    this.initLoading = true;
    await this.initialize();
    this.initLoading = false;
  }

  async refresh() {
    this.savedFilterQuery = null;
    this.$refs.filter.resetFilter();
    this.initLoading = true;
    await this.initialize();
    this.initLoading = false;
  }

  hasProfileImage(employee: any) {
    if (employee.profileImage) {
      return employee.profileImage;
    }
    return "";
  }

  async initialize() {
    await this.workForceService
      .get<IEmployeeList[]>(
        `/employee?${this.savedFilterQuery ? this.savedFilterQuery : ""}${
          this.savedPaginationQuery
            ? this.savedPaginationQuery
            : "PageIndex=0&PageSize=" + this.perPage
        }`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.employees = response.content.items;
      });

    if (!this.checkKey(this.filterData, "DepartmentId")) {
      await this.workForceService
        .get<IDepartment[]>(`/departments`, false)
        .then((response: any) => {
          const department = {
            key: "DepartmentId",
            keyLabel: "Department",
            dataType: "dropDowns",
            dropDownOptions: [] as { value: string; label: string }[],
          };
          response.content.items.forEach(
            (item: { id: string; name: string }) => {
              const data = {
                value: item.id,
                label: item.name,
              };
              department.dropDownOptions.push(data);
            }
          );
          this.filterData.push(department);
        });
    }

    if (!this.checkKey(this.filterData, "ManagerId")) {
      await this.workForceService
        .get<IDepartment[]>(`/Employee/Managers`, false)
        .then((response: any) => {
          const manager = {
            key: "ManagerId",
            keyLabel: "Reports To",
            dataType: "dropDowns",
            dropDownOptions: [] as { value: string; label: string }[],
          };
          response.content.forEach(
            (item: { id: any; firstName: string; lastName: string }) => {
              const data = {
                value: item.id,
                label: item.firstName + " " + item.lastName,
              };
              manager.dropDownOptions.push(data);
            }
          );
          this.filterData.push(manager);
        });
    }
  }
  initLoading = false;
  async created() {
    this.initLoading = true;
    await this.initialize();
    this.initLoading = false;
  }

  async deleteEmployee(employeeId: number) {
    this.workForceService.delete(`/employee/${employeeId}`).then(() => {
      this.initialize();
    });
  }

  async inActiveEmployee(id: number) {
    this.employeeId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Deactivate Employee?",
      message: "Are you sure you want to deactivate this employee?",
      okButton: "Deactivate",
      cancelButton: "Cancel",
      icon: "warning",
      type: "confirmation",
    });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
    if (modal) {
      await this.okClick();
      this.employeeId = 0;
    } else {
      this.employeeId = 0;
      return false;
    }
  }

  okClick() {
    this.workForceService.delete(`/employee/${this.employeeId}`).then(() => {
      this.initialize();
    });
  }

  employee: IManager = {
    employeeId: 0,
    managerId: 0,
  };
  dlgShow = false;
  async showManagerDialog(managerId: number, employeeId: number) {
    const modal = await this.$refs.dropDown.show({
      employeeId: employeeId,
      managerId: managerId,
    });
    if (modal) {
      await this.initialize();
    }
  }

  async resendVerificationEmail(employeeId: number) {
    const modal = await this.$refs.confirmDialogue.show({
      title: "Resend Confirmation?",
      message: "Are you sure you want to resend confirmation email?",
      okButton: "Ok",
      cancelButton: "Cancel",
      theme: "primary",
      type: "confirmation",
    });
    if (modal) {
      this.workForceService
        .postOrPut<void>(
          `/employee/resendEmail/${employeeId}`,
          null,
          employeeId.toString()
        )
        .then((response: any) => {
          if (response.isSuccess) {
            notify(
              "Confirmation email sent successfully.",
              "Success",
              "success"
            );
          } else {
            notify(response.errors, "Error", "danger");
          }
        });
    } else {
      return false;
    }
  }
}
