<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Header-->
  <div class="custom-container">
    <page-title>
      <template #title>
        <span class="h4 text-primary">Employees</span>
      </template>

      <template #button>
        <div class="">
          <button
            class="btn btn-primary mr-3"
            @click="goToRoute('/admin/employee/new')"
          >
            <font-awesome-icon class="mr-1" icon="plus-circle" />
            Add new
          </button>
        </div>
        <div class="">
          <button
            class="btn btn-outline-primary mr-3"
            @click="goToRoute('employee/upload')"
          >
            <font-awesome-icon class="mr-1" icon="upload" />
            Bulk upload
          </button>
        </div>
        <div class="">
          <button class="btn btn-secondary" @click="refresh()">
            <font-awesome-icon class="mr-1" icon="history" />
            Refresh
          </button>
        </div>
      </template>
    </page-title>

    <!--  Filter-->
    <Filter
      class="mb-4"
      ref="filter"
      :sort-prop="[
        {
          key: 'First Name',
          value: 'firstName',
        },
        { key: 'Last Name', value: 'lastName' },
        { key: 'Department', value: 'department' },
        { key: 'Manager', value: 'manager' },
      ]"
      :filtersProp="filterData"
      @clear-filter="clearFilter"
      @apply-filter="filter($event)"
      @apply-sort="sort($event)"
      v-if="!initLoading"
    ></Filter>

    <template v-if="!initLoading">
      <template v-if="employees.length > 0">
        <template v-for="item in employees" v-bind:key="item.id">
          <card-row
            :class="`${
              item.id == authId
                ? 'bg-primary text-white'
                : item.isActive
                ? ''
                : 'bg-secondary-light'
            }`"
          >
            <template #avatar>
              <profile-image
                :employee-id="item.id"
                :image="hasProfileImage(item)"
                mode="list"
                :gender="item.gender"
              ></profile-image>
            </template>
            <!--        Details-->
            <template #details>
              <!--            Row 1: Name and status-->
              <div class="d-flex align-items-center">
                <!--            Name-->

                <div :class="`font-weight-bold mr-1`">
                  <router-link
                    v-if="canInviteOrEdit(authRole, item.role)"
                    :class="`nav-link p-0 m-0 ${
                      item.id == authId ? 'text-white' : 'text-primary'
                    }`"
                    :to="`/admin/employee/edit/${item.id}`"
                  >
                    {{ item.firstName }} {{ item.lastName }}
                  </router-link>
                  <span
                    :class="`nav-link p-0 m-0 ${
                      item.id == authId ? 'text-white' : 'text-primary'
                    }`"
                    v-else
                  >
                    {{ item.firstName }} {{ item.lastName }}
                  </span>
                </div>
                <small
                  v-if="item.department"
                  :class="`mr-1 ${
                    item.id == authId ? 'text-light' : 'text-muted'
                  }`"
                  >({{ item.department }})</small
                >

                <!--            Status-->
                <div v-if="item.role" class="">
                  <div
                    :class="`badge badge-pill ${
                      item.id == authId ? 'badge-primary-dark' : 'badge-primary'
                    }`"
                  >
                    Verified
                    <font-awesome-icon class="ml-1" icon="check-circle" />
                  </div>
                </div>

                <div v-if="item.isActive == false" class="ml-1">
                  <div :class="`badge badge-pill badge-dark`">
                    Deactivated
                    <font-awesome-icon class="ml-1" icon="ban" />
                  </div>
                </div>

                <div v-if="item.id == authId" class="ml-1">
                  <div :class="`badge badge-pill badge-dark`">It's Me</div>
                </div>
              </div>

              <!--            Row 2: Role-->
              <div class="d-flex align-items-center my-1">
                <span class="">Role: </span>
                <div
                  v-if="
                    (authRole == 'Org Admin' || authRole == 'Admin') &&
                    item.isActive
                  "
                  class="dropdown ml-1"
                >
                  <a
                    :class="`badge py-1 badge-${
                      item.role == 'Manager' || item.role == 'Org Admin'
                        ? item.id == authId
                          ? 'primary-dark'
                          : 'info'
                        : 'secondary'
                    } dropdown-toggle`"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ item.role || "Employee" }}
                  </a>
                  <div
                    class="dropdown-menu rounded shadow-sm border-primary bg-light py-1 px-2"
                  >
                    <a
                      @click.prevent="setRole(item.id, 'Org Admin')"
                      :class="`${
                        item.role == 'Org Admin' ? 'active' : ''
                      } dropdown-item mx-0 my-1 px-1 py-0 rounded-pill`"
                      type="button"
                      >Org Admin</a
                    >
                    <a
                      @click.prevent="setRole(item.id, 'Manager')"
                      :class="`${
                        item.role == 'Manager' ? 'active' : ''
                      } dropdown-item mx-0 my-1 px-1 py-0 rounded-pill`"
                      type="button"
                      >Manager</a
                    >
                    <a
                      @click.prevent="setRole(item.id, 'Employee')"
                      :class="`${
                        item.role == '' || item.role == 'Employee'
                          ? 'active'
                          : 'Employee'
                      } dropdown-item mx-0 my-1 px-1 py-0 rounded-pill`"
                      type="button"
                      >Employee</a
                    >
                  </div>
                </div>
                <a
                  v-else
                  :class="`badge py-1 badge-${
                    item.role == 'Manager' || item.role == 'Org Admin'
                      ? item.id == authId
                        ? 'primary-dark'
                        : 'info'
                      : 'secondary'
                  } role-disabled`"
                >
                  {{ item.role || "Employee" }}
                </a>
              </div>

              <!--            Row 3: Reports to-->
              <div class="d-flex align-items-center my-1">
                <span class="">Reports To: </span>
                <div v-if="item.manager && item.isActive" class="ml-1">
                  <a
                    :class="`nav-link m-0 p-0 cursor-pointer ${
                      item.id == authId ? 'text-white' : ''
                    }`"
                    @click.prevent="showManagerDialog(item.managerId, item.id)"
                    >{{ item.manager }}</a
                  >
                </div>
                <div
                  v-else-if="
                    (item.role == `Employee` || item.role == `Manager`) &&
                    item.isActive
                  "
                  class="ml-1"
                >
                  <a
                    :class="`nav-link ${
                      item.id == authId ? 'text-white' : 'text-primary'
                    } m-0 p-0 cursor-pointer`"
                    @click.prevent="showManagerDialog(item.managerId, item.id)"
                    >Select a Manager</a
                  >
                </div>
                <div v-else class="ml-1">
                  <span
                    :class="`${
                      item.id == authId ? 'text-white' : 'text-muted'
                    }`"
                    >Not Available</span
                  >
                </div>
              </div>
            </template>

            <!--        Actions-->
            <template #actions>
              <!--              resend-->

              <div v-if="!item.role">
                <a
                  class="btn btn-sm btn-warning"
                  @click.prevent="resendVerificationEmail(item.id)"
                >
                  <font-awesome-icon class="mr-1" icon="history" />
                  Resend Email
                </a>
              </div>

              <!--              Goals-->
              <router-link
                v-if="item.isActive && canInviteOrEdit(authRole, item.role)"
                :class="`btn btn-sm ml-1 ${
                  item.id == authId ? 'btn-light' : 'btn-secondary'
                } px-2`"
                :to="{
                  name: 'Goals',
                  params: { id: item.id },
                }"
              >
                <font-awesome-icon class="mr-1" icon="bullseye" />
                Goals
              </router-link>

              <!--              Notes-->
              <router-link
                v-if="item.isActive && canInviteOrEdit(authRole, item.role)"
                :class="`btn btn-sm ml-1 ${
                  item.id == authId ? 'btn-light' : 'btn-secondary'
                } px-2`"
                :to="{
                  name: 'Notes',
                  params: { id: item.id },
                }"
              >
                <font-awesome-icon class="mr-1" icon="clipboard" />
                Notes
              </router-link>

              <!--              Performance Links-->
              <span
                class="ml-1"
                v-if="
                  item.isActive &&
                  canInviteOrEdit(authRole, item.role) &&
                  item.id != authId
                "
              >
                <button
                  class="btn btn-sm btn-xs btn-primary-dark"
                  @click.prevent="
                    this.$router.push({
                      name: 'Review',
                      params: { id: item.id },
                    })
                  "
                >
                  <font-awesome-icon class="mr-1" icon="envelope-open-text" />
                  Invite for Review
                </button>
              </span>

              <!--              edit-->
              <router-link
                v-if="canInviteOrEdit(authRole, item.role)"
                :class="`btn btn-sm ml-1 ${
                  item.id == authId ? 'btn-light' : 'btn-primary'
                } px-2`"
                :to="{
                  path: `/admin/employee/edit/${item.id}`,
                }"
              >
                <font-awesome-icon class="mr-1" icon="pencil-alt" />
                Edit
              </router-link>
            </template>
          </card-row>
        </template>

        <!--        Pagination-->
        <PaginationComponent
          v-if="totalCount > 15"
          :page-count="totalPages"
          :perPage="perPage"
          :click-handler="goToPage"
        ></PaginationComponent>
      </template>

      <template v-else>
        <div class="card mb-3 shadow-sm">
          <div class="card-body border-0 rounded py-3">
            <span class="h6">No Data Available</span>
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <div class="card-body shadow-sm border rounded mt-3">
        <loading-component></loading-component>
      </div>
    </template>
  </div>

  <!--  Modal-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
  <DropDownModal ref="dropDown"></DropDownModal>
</template>

<script src="./employees-list.ts"></script>

<style></style>
